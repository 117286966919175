import React, {useState,useEffect} from "react";
import { navigate } from "gatsby";

const NotFoundPage = (props) => {
  // useEffect(() => {
  //   const alias = props.location.pathname.indexOf('r') !== -1 ?  props.location.pathname.split('/')[2] : ''
  //   if (alias) {
  //   navigate(`/redirect?name=${alias}`)
  //   }
  // })

  return (
    <div>
    <span>(404) NotFound Page</span>
    </div>
  )
}

export default NotFoundPage